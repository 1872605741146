<template>
  <div>
    <!-- <desktop-stats :stats="stats" label="Users Stats" />
    <mobile-stats :stats="stats" label="Users Stats" /> -->
    <data-table v-model="context" :columns="columns" endpoint="users">
      <template slot-scope="{ row }">
        <td class="px-6 py-2 text-sm font-medium text-gray-900">
          {{ row.fullname }}
        </td>
        <td
          class="hidden md:table-cell px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-left"
        >
          {{ row.email }}
        </td>
        <td
          class="hidden md:table-cell px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-left"
        >
          {{ row.phone }}
        </td>
        <td
          class="hidden md:table-cell px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-left"
        >
          <template v-if="row.Role.role_name !== 'Super Admin'">
            <toggle v-model="row.active" :id="row.id" @change="change" />
          </template>
        </td>
        <td
          class="hidden md:table-cell px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-left"
        >
          <span
            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full border"
            :class="roles[row.Role.role_name.split(' ').join('_').toLowerCase()]"
            >{{ row.Role && row.Role.role_name }}</span
          >
        </td>
        <td class="px-6 py-2 whitespace-nowrap text-center text-sm font-medium">
          <data-table-row-dropdown
            v-model="context[row.id]"
            @event="event"
            :item="row"
            :actions="actions"
          />
        </td>
      </template>
    </data-table>
  </div>
</template>

<script>
import http from '@/utils/http'

export default {
  name: 'Dashboard',
  props: [],
  components: {
    /* DesktopStats, MobileStats, */
  },
  data() {
    return {
      roles: {
        super_admin: 'text-green-800 bg-green-100 border-green-400',
        admin: 'text-blue-800 bg-blue-100 border-blue-400',
      },
      columns: [
        {
          label: '',
          width: '5%',
          align: 'text-left',
          type: 'check',
        },
        {
          label: 'Name',
          width: '25%',
          align: 'text-left',
        },
        {
          label: 'Email',
          width: '20%',
          align: 'text-left',
        },
        {
          label: 'Phone',
          width: '15%',
          align: 'text-left',
        },
        {
          label: 'Status',
          width: '5%',
          align: 'text-left',
        },
        {
          label: 'Role',
          width: '10%',
          align: 'text-center',
        },
        {
          label: '',
          width: '20%',
          align: 'text-left',
        },
      ],
      context: [],
      stats: [
        {
          short: 'OK',
          label: 'Working Instances',
          class: 'bg-green-600',
          count: 'Total Working 145',
        },
        // {
        //   short: 'WA',
        //   label: 'Upgradable Instances',
        //   class: 'bg-yellow-400',
        //   count: '32 needed upgrade',
        // },
        // {
        //   short: 'ER',
        //   label: 'Broken Instances',
        //   class: 'bg-red-600',
        //   count: '12 with error',
        // },
        // {
        //   short: 'NE',
        //   label: 'New Instances',
        //   class: 'bg-blue-600',
        //   count: '2 newly created',
        // },
      ],
      actions: {
        label: 'Actions',
        options: [
          {
            label: 'Edit User',
            icon: '&#x1F4AC;',
            component: 'new-user',
            permission: 'edit_user',
          },
          {
            label: 'Delete User',
            icon: '&#x21A9;&#xFE0F;',
            component: 'delete',
            permission: 'delete_user',
          },
        ],
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.setNewButton({
        label: 'User',
        component: 'new-user',
        endpoint: 'users',
        notifier: 'reload-data-table',
      })
      this.setPage({ title: 'Users' })
    },
    event(payload) {
      const { component, item } = payload

      this.setModal({ show: true, component, data: item, notifier: 'reload-data-table' })
    },
    change(id) {
      http.put(`users/${id}/status`).then(() => {})
    },
    close() {
      this.context = false
    },
  },
}
</script>

<style></style>
